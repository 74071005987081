import "./ResetPassword.css";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPassword,
  resetPasswordState,
} from "../../redux/resetPass/resetPass";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import translations from "../../translations.json";

export function ResetPassword() {
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const location = useLocation();
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [token, setToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetPassState = useSelector((state) => state.resetPassword);

  // Function for validating the token
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    } else {
      navigate("/login");
    }
  }, [location, dispatch, navigate]);

  function onPassReset(e) {
    e.preventDefault();
    dispatch(
      resetPassword({
        new_password: newPass,
        confirm_password: confirmPass,
        token: token,
      })
    );
  }

  useEffect(() => {
    if (resetPassState.status === "error") {
      setTimeout(() => {
        dispatch(resetPasswordState());
      }, 2500);
    }
  }, [resetPassState.status, dispatch]);

  const isStatusLoading = resetPassState.status === "loading";
  const isBtnDisabled = isStatusLoading || !newPass || !confirmPass;

  return (
    <section className="resetPassContainer">
      <div className="logo">
        <img src={Logo} alt={translations.resetPassPage.iconAlts.logoAlt} />
      </div>
      <div className="formBg">
        <img
          className="background"
          src={Background}
          alt={translations.resetPassPage.iconAlts.formBgAlt}
        />
        <div className="flags">
          <div className={!isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={NorwayFlag}
              alt={translations.resetPassPage.iconAlts.norwayFlagAlt}
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={EnglishFlag}
              alt={translations.resetPassPage.iconAlts.englishFlagAlt}
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>

        {resetPassState.status === "success" ? (
          <div className="passChanged">
            <h2>{translations.resetPassPage.passChanged.heading}</h2>
            <p>{translations.resetPassPage.passChanged.message}</p>
            <Link to={"/login"} className="backToLoginBtn">
              {translations.resetPassPage.passChanged.backToLoginBtn}
            </Link>
          </div>
        ) : (
          <form className="resetPassForm" onSubmit={onPassReset}>
            <h2>{translations.resetPassPage.resetPassForm.heading}</h2>
            <p>{translations.resetPassPage.resetPassForm.instruction}</p>
            <div className="inputs">
              <div className="input">
                <label htmlFor="newPass">
                  {translations.resetPassPage.resetPassForm.newPasswordLabel}
                </label>
                <input
                  type="password"
                  id="newPass"
                  required
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                />
              </div>
              <div className="input">
                <label htmlFor="confirmNewPass">
                  {
                    translations.resetPassPage.resetPassForm
                      .confirmPasswordLabel
                  }
                </label>
                <input
                  type="password"
                  id="confirmNewPass"
                  required
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </div>
              <button
                className="createPassBtn"
                type="submit"
                disabled={isBtnDisabled}
              >
                {translations.resetPassPage.resetPassForm.createPasswordButton}
              </button>
            </div>
            <div className="loadingNotification">
              {isStatusLoading && <LoadingSpinner />}
            </div>
            <div className="errorNotification">
              {resetPassState.status === "error" && (
                <ErrorMsg msg={resetPassState.error} />
              )}
            </div>
          </form>
        )}
      </div>
    </section>
  );
}
