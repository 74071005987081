// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMsg_msg__fg1PP {
  width: 200px;
  min-width: 500px;
  background-color: #ddeefd;
  padding: 20px 0;
  border-radius: 8px;
  background-color: red;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/errorMsg/ErrorMsg.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".msg {\r\n  width: 200px;\r\n  min-width: 500px;\r\n  background-color: #ddeefd;\r\n  padding: 20px 0;\r\n  border-radius: 8px;\r\n  background-color: red;\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  text-align: center;\r\n  padding: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"msg": `ErrorMsg_msg__fg1PP`
};
export default ___CSS_LOADER_EXPORT___;
