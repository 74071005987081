import "./SuccessNotification.css";
import CheckIcon from "../../images/deliveries/deliveriesModals/checkIcon.svg";
import translations from "../../translations.json";

export function SuccessNotificationModal({ msg }) {
  return (
    <div className="successNotification">
      <img
        src={CheckIcon}
        alt={translations.deleteConfirmationMessage.checkIconAlt}
      />
      <div>
        <div className="headDiv">
          {translations.deleteConfirmationMessage.headerText}
        </div>
        <div style={{ color: "#676c7e", lineHeight: "24px" }}>{msg}</div>
      </div>
    </div>
  );
}
