import { useEffect, useRef } from "react";

import "./ThreeDotsModal.css";
import EditBtn from "../../images/deliveries/deliveriesModals/editIcon.svg";
import DeleteBtn from "../../images/deliveries/deliveriesModals/deleteIcon.svg";
import ClockIcon from "../../images/drivers/clockIcon.svg";
import {
  onDelete,
  onEdit,
  toggleAddNewDriverModal,
  toggleDriverInfoModal,
  toggleThreeDotsModal,
  setIsDriverRoutes,
  setShowThreeDotsModal,
} from "../../redux/globalFunctions/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../translations.json";

export function ThreeDotsModal() {
  const { modalPosition, isWithHistory, selectedDriverId } = useSelector(
    (state) => state.global
  );

  const modalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the modal is open and the clicked element is not the modal
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(setShowThreeDotsModal(false)); // Close the modal
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleEditClick() {
    if (isWithHistory) {
      dispatch(toggleAddNewDriverModal());
    } else {
      dispatch(onEdit());
    }
  }

  return (
    <div
      ref={modalRef}
      className="threeDotsModal"
      style={{
        position: "absolute",
        top: modalPosition.y,
        left: modalPosition.x,
      }}
    >
      <div className="btns">
        {isWithHistory && (
          <img
            src={ClockIcon}
            alt={translations.threeDotsModal.clockIconAlt}
            onClick={() => {
              dispatch(toggleThreeDotsModal(selectedDriverId));
              dispatch(toggleDriverInfoModal());
              dispatch(setIsDriverRoutes(false));
            }}
          />
        )}
        <img
          src={EditBtn}
          alt={translations.threeDotsModal.editIconAlt}
          onClick={handleEditClick}
        />
        <img
          src={DeleteBtn}
          alt={translations.threeDotsModal.deleteIconAlt}
          onClick={() => dispatch(onDelete())}
        />
      </div>
      <div className={isWithHistory ? "squareDivHistory" : "squareDiv"}></div>
    </div>
  );
}
