import { getStatusClass } from "../../helperFunctions/getStatusClass";
import "./DashboardTable.css";

export function DashboardTable({
  order_number,
  dropoff_address,
  date,
  driver_name,
  status,
  onClick,
}) {
  return (
    <div
      className="tableRow"
      onClick={() => {
        onClick();
      }}
    >
      <div className="tableData">{driver_name}</div>
      <div className="tableData">{order_number}</div>
      <div className="tableData">{date}</div>
      <div className="tableData">{dropoff_address}</div>
      <div className={getStatusClass(status)}>{status}</div>
    </div>
  );
}
