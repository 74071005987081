import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const registerUser = createAsyncThunk(
  "user/register",
  async (data, thunkAPI) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirm_password);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("user_type_id", data.user_type_id);
    formData.append("phone_number", data.phone_number);

    if (data.avatar) {
      formData.append("avatar", data.avatar);
    }

    if (data.token) {
      formData.append("token", data.token);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${apiUrl}/register?token=${data.token}`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Registration failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
