import { createSlice } from "@reduxjs/toolkit";
import { validateToken } from "../thunks/validateDriverInvitationThunk";

const validateTokenSlice = createSlice({
  name: "validateToken",
  initialState: {
    status: "idle",
    error: null,
    isValid: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(validateToken.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(validateToken.fulfilled, (state, action) => {
        console.log(action.payload);
        state.status = "success";
        state.isValid = action.payload.msg === "Valid token";
        state.error = null;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.status = "error";
        state.isValid = false;
        state.error = action.payload;
      });
  },
});

export default validateTokenSlice.reducer;
