import { createSlice } from "@reduxjs/toolkit";
import { fetchDeliveryHistory } from "../thunks/getDeliveryHistoryThunk";

const getDeliveryHistorySlice = createSlice({
  name: "deliveryHistory",
  initialState: {
    deliveries: [],
    dropoff_addresses: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeliveryHistory.fulfilled, (state, action) => {
        state.dropoff_addresses = action.payload.dropoff_addresses;
        state.deliveries = action.payload.packages;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(fetchDeliveryHistory.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default getDeliveryHistorySlice.reducer;
