// slices/getDeliverySlice.js

import { createSlice } from "@reduxjs/toolkit";
import { fetchDeliveries } from "../thunks/getDeliveryThunk";

const getDeliverySlice = createSlice({
  name: "deliveries",
  initialState: {
    deliveries: [],
    delivery: {},
    total_count: 0,
    filtered_count: 0,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {
    resetDeliveryState(state) {
      state.delivery = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDeliveries.fulfilled, (state, action) => {
        state.delivery = action.payload;
        state.deliveries = action.payload.deliveries; // Adjust according to your API response
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.status = "succeeded";
        state.error = null; // Reset error state upon success
      })
      .addCase(fetchDeliveries.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload; // Payload here will be the error message
      });
  },
});

export const { resetDeliveryState } = getDeliverySlice.actions;
export default getDeliverySlice.reducer;
