import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSearchQuery } from "../../redux/misc/slices/searchSlice";
import SearchIcon from "../../images/header/searchIcon.svg";
import NotificationIcon from "../../images/header/notificationIcon.svg";
import EnglishFlag from "../../images/header/EnglishFlag.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import "./Header.css";
import { headerText } from "../../translations";

export function Header() {
  const [query, setQuery] = useState("");
  const [language, setLanguage] = useState("en"); // State for selected language
  const [showSecondaryFlag, setShowSecondaryFlag] = useState(false); // State to show/hide secondary flag
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.query);

  // Update local query state when Redux state changes
  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    dispatch(setSearchQuery(value)); // Dispatch the action to set the search query
  };

  const handlePrimaryFlagClick = () => {
    setShowSecondaryFlag((prev) => !prev); // Toggle visibility of secondary flag
  };

  const handleSecondaryFlagClick = () => {
    // Swap languages and hide secondary flag
    setLanguage((prevLanguage) => (prevLanguage === "en" ? "no" : "en"));
    setShowSecondaryFlag(false);
  };

  return (
    <section className="header">
      <div className="contentHeader">
        <div className="search">
          <input
            type="text"
            id="search"
            placeholder={headerText.searchPlaceholder}
            value={query}
            onChange={handleSearch}
          />
          <label htmlFor="search">
            <img src={SearchIcon} alt="search icon" />
          </label>
        </div>
        <div className="headerIcons">
          <span>
            <img src={NotificationIcon} alt="notification icon" />
          </span>
          <div className="flagContainer">
            {/* Primary flag */}
            <img
              src={language === "en" ? EnglishFlag : NorwayFlag}
              alt={language === "en" ? "english flag" : "norway flag"}
              onClick={handlePrimaryFlagClick}
            />
            {/* Secondary flag */}
            {showSecondaryFlag && (
              <img
                src={language === "en" ? NorwayFlag : EnglishFlag}
                alt={language === "en" ? "norway flag" : "english flag"}
                className="secondaryFlag"
                onClick={handleSecondaryFlagClick}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
