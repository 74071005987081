import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./AddCustomerAddress.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { createCustomer } from "../../redux/customer/thunks/createCustomerThunk";
import { resetCreateCustomerStatus } from "../../redux/customer/slices/createCustomerSlice";
import { updateCustomer } from "../../redux/customer/thunks/updateCustomerThunk";
import { resetUpdateCustomerStatus } from "../../redux/customer/slices/updateCustomerSlice";
import MapboxComponent from "../mapboxComponent/MapboxComponent";
import { useLocation } from "react-router-dom";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleAddNewCustomerAddress,
  setErrorMsg,
  setSuccessMsg,
  onDeleteConfirm,
  onErrorMessage,
  setIsEditMode,
  setIsEditModalShown,
  setIsModalsBgShown,
  setIsDeleteConfirmShown,
  setIsErrorMsg,
} from "../../redux/globalFunctions/globalFnSlice";
import translations from "../../translations.json";

export function AddCustomerAddress() {
  const { isEditMode, selectedDataForEdit, addressData } = useSelector(
    (state) => state.global
  );
  const createCustomerState = useSelector((state) => state.createCustomer);
  const updateCustomerState = useSelector((state) => state.updateCustomer);

  const hasMounted = useRef(false);
  const location = useLocation();
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  // State for form inputs
  const [formData, setFormData] = useState({
    c_name: "",
    c_email: "",
    c_phonenumber: "",
    c_address: "",
  });

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  //Update address
  const changeAddress = (newAddress) => {
    setFormData((prevData) => ({
      ...prevData,
      c_address: newAddress,
    }));
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Phone number validation.
    if (id === "c_phonenumber") {
      const phoneRegex = /^\+?[0-9]*$/;

      // If the input matches the regex, update the state
      if (phoneRegex.test(value)) {
        setFormData((prevData) => ({
          ...prevData,
          c_phonenumber: value,
        }));
      }
    } else {
      // For other fields, just update the state
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(
        updateCustomer({
          c_id: selectedDataForEdit.c_id,
          ...formData,
        })
      );
    } else {
      dispatch(createCustomer(formData));
    }
  };

  // Handler for createCustomer status
  useEffect(() => {
    if (createCustomerState.status === "success") {
      dispatch(setSuccessMsg(createCustomerState.customer.msg));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(onDeleteConfirm());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(resetCreateCustomerStatus());
      }, 1500);
    } else if (createCustomerState.status === "error") {
      dispatch(setErrorMsg(createCustomerState.error));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
        dispatch(resetCreateCustomerStatus());
      }, 1500);
    }
  }, [createCustomerState.status, dispatch]);

  // Handler for updateCustomer status
  useEffect(() => {
    if (updateCustomerState.status === "success") {
      dispatch(toggleAddNewCustomerAddress());
      dispatch(setSuccessMsg(updateCustomerState.customer.msg));
      dispatch(onDeleteConfirm());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(resetUpdateCustomerStatus());
      }, 1500);
    } else if (updateCustomerState.status === "error") {
      dispatch(setErrorMsg(updateCustomerState.error));
      dispatch(toggleAddNewCustomerAddress());
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
        dispatch(resetUpdateCustomerStatus());
      }, 1500);
    }
  }, [updateCustomerState.status, dispatch]);

  // If isEditMode is true then populate the fields with the data from selectedDataForEdit.
  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      setFormData({
        c_name: selectedDataForEdit.c_name || "",
        c_email: selectedDataForEdit.c_email || "",
        c_phonenumber: selectedDataForEdit.c_phonenumber || "",
        c_address: selectedDataForEdit.c_address || "",
      });
    }
  }, [isEditMode, selectedDataForEdit]);

  const isStatusLoading =
    createCustomerState.status === "loading" ||
    updateCustomerState.status === "loading";

  const isEditModeFields =
    formData.c_name === "" ||
    formData.c_email === "" ||
    formData.c_phonenumber === "" ||
    formData.c_address === "";

  function handleCloseModal() {
    if (
      location.pathname === "/deliveries" ||
      location.pathname === "/dashboard"
    ) {
      dispatch(toggleAddNewCustomerAddress());
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
      dispatch(setIsEditMode(false));
    } else {
      dispatch(toggleAddNewCustomerAddress());
    }
  }

  useEffect(() => {
    if (
      location.pathname === "/deliveries" &&
      createCustomerState.status === "success"
    ) {
      setTimeout(() => {
        dispatch(setIsModalsBgShown(true));
        dispatch(setIsEditModalShown(true));
        dispatch(setIsEditMode(false));
      }, 1500);
    }
  }, [createCustomerState.status]);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  // Filtering the data to get the correct items.
  const dataForFiltering = addressData?.addressData?.[0]?.context ?? [];
  const postalCode = dataForFiltering.find((entry) =>
    entry.id.startsWith("postcode")
  );
  const country = dataForFiltering.find((entry) =>
    entry.id.startsWith("country")
  );
  const city = dataForFiltering.find((entry) => entry.id.startsWith("place"));

  const headerText = isEditMode
    ? translations.addCustomerModal.editText
    : translations.addCustomerModal.addText;

  return (
    <div className="addCustomerAddress" ref={modalRef}>
      <div className="addCustomerAddressHeading">
        <h6>{headerText}</h6>
        <span className="closeBtn" onClick={handleCloseModal}>
          <img
            src={CloseIcon}
            alt={translations.addCustomerModal.closeIconAlt}
          />
        </span>
      </div>
      <form className="addCustomerAddressForm">
        <div className="addCustomerElements">
          <div className="formElement">
            <label htmlFor="c_name">
              {translations.addCustomerModal.form.customer}
            </label>
            <input
              type="text"
              id="c_name"
              value={formData.c_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="formElement">
            <label htmlFor="c_email">
              {translations.addCustomerModal.form.email}
            </label>
            <input
              type="email"
              id="c_email"
              value={formData.c_email}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="addCustomerElements">
          <div className="formElement">
            <label htmlFor="c_phonenumber">
              {translations.addCustomerModal.form.phone}
            </label>
            <input
              type="text"
              id="c_phonenumber"
              value={formData.c_phonenumber}
              onChange={handleInputChange}
              required
              pattern="^\+?[0-9]*$"
              title="Phone number should contain only numbers and can start with +"
            />
          </div>
          <div className="formElement">
            <label htmlFor="c_country">
              {translations.addCustomerModal.form.country}
            </label>
            <input
              type="text"
              id="country"
              disabled
              required
              value={country?.text}
            />
          </div>
        </div>
        <div className="addCustomerElements">
          <div className="formElement">
            <label htmlFor="c_city">
              {translations.addCustomerModal.form.city}
            </label>
            <input
              type="text"
              id="c_city"
              disabled
              required
              value={city?.text}
            />
          </div>
          <div className="formElement">
            <label htmlFor="c_postalcode">
              {translations.addCustomerModal.form.postalCode}
            </label>
            <input
              type="text"
              id="c_postalcode"
              disabled
              required
              value={postalCode?.text}
            />
          </div>
        </div>
        <div className="formElement">
          <label htmlFor="c_address">
            {translations.addCustomerModal.form.address}
          </label>
          <input
            type="text"
            id="c_address"
            value={formData.c_address}
            onChange={handleInputChange}
            required
          />
        </div>
        <MapboxComponent
          address={formData.c_address}
          updateAddress={changeAddress}
        />
      </form>
      <button
        className="saveBtn"
        onClick={handleSubmit}
        disabled={isStatusLoading || isEditModeFields}
      >
        {isStatusLoading ? (
          <span className="loader"></span>
        ) : (
          translations.addCustomerModal.saveBtn
        )}
      </button>
    </div>
  );
}
