import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../images/drivers/calendarIcon.svg";
import "./DriverInfo.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { DriverRoute } from "../UI/DriverRoute";
import { driverInfoText } from "../../translations";
import { fetchDeliveryHistory } from "../../redux/package/thunks/getDeliveryHistoryThunk";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import { toggleDriverInfoModal } from "../../redux/globalFunctions/globalFnSlice";

export function DriverInfo() {
  const { isDriverRoutes, selectedDriverId } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");

  // Fetch driver's data from Redux store
  const drivers = useSelector((state) => state.drivers.drivers);

  // Find the selected driver's details
  const selectedDriver = drivers.find((driver) => driver.u_id === selectedDriverId);

  // Fetch deliveries based on default filters when isDriverRoutes changes
  useEffect(() => {
    if (!isDriverRoutes) {
      fetchData();
    }
  }, [isDriverRoutes, fromDate, toDate, selectedAddress]);

  const fetchData = () => {
    const formatDate = (date, endOfDay = false) => {
      if (!date) return null;
      const formattedDate = new Date(date);
      if (endOfDay) {
        formattedDate.setHours(23, 59, 59, 999);
      } else {
        formattedDate.setHours(0, 0, 0, 0);
      }
      const year = formattedDate.getFullYear();
      const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
      const day = String(formattedDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}T${formattedDate.toTimeString().split(" ")[0]}.000Z`;
    };

    const filters = {
      driver_id: selectedDriverId,
      from_date: formatDate(fromDate) || null,
      to_date: formatDate(toDate, true) || null,
      address: selectedAddress || null,
    };

    // Dispatch action to fetch filtered deliveries or default deliveries
    dispatch(fetchDeliveryHistory(filters));
  };

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const handleAddressChange = (event) => {
    const address = event.target.value;
    if (address === "default") {
      // Reset address filter and refetch all results
      setSelectedAddress("");
    } else {
      // Set the selected address and refetch filtered results
      setSelectedAddress(address);
    }
  };

  // Retrieve data from Redux store
  const currentDeliveriesState = useSelector((state) => state.currentDeliveries);
  const deliveryStatus = currentDeliveriesState.status;
  const deliveries = deliveryStatus === "succeeded" ? currentDeliveriesState.deliveries : [];

  const historyDeliveriesState = useSelector((state) => state.deliveryHistory);
  const historyStatus = historyDeliveriesState.status;
  const historyDeliveries = historyStatus === "succeeded" ? historyDeliveriesState.deliveries : [];

  const dropoffAddresses = useSelector((state) => state.deliveryHistory.dropoff_addresses);

  const modalRef = useRef(null);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(toggleDriverInfoModal()));
  useClickOutsideClose(modalRef, () => dispatch(toggleDriverInfoModal()));

  return (
    <div className="driverRoutesModal" ref={modalRef}>
      <div className="driverRoutesHeader">
        <div>
          {isDriverRoutes
            ? `${driverInfoText.headerTextRoutes} ${
                selectedDriver ? `${selectedDriver.u_firstname} ${selectedDriver.u_lastname}` : ""
              }`
            : `${driverInfoText.headerTextHistory} ${
                selectedDriver ? `${selectedDriver.u_firstname} ${selectedDriver.u_lastname}` : ""
              }`}
        </div>
        <img
          src={CloseIcon}
          alt="X icon"
          className="closeDriverRoutesBtn"
          onClick={() => dispatch(toggleDriverInfoModal())}
        />
      </div>
      {!isDriverRoutes && (
        <div className="driverHistoryFilters">
          <div>
            {driverInfoText.filterBy}
            <select name="address" id="address" value={selectedAddress || "default"} onChange={handleAddressChange}>
              <option value="default">{driverInfoText.addressSelect}</option>
              {[...new Set(dropoffAddresses.map((address) => address))].map((address, index) => (
                <option key={index} value={address}>
                  {address}
                </option>
              ))}
            </select>
            <div className="dateFilter">
              <img src={CalendarIcon} alt="Calendar icon" />
              <DatePicker
                selected={fromDate}
                onChange={handleFromDateChange}
                selectsStart
                startDate={fromDate}
                endDate={toDate}
                placeholderText="From Date"
                dateFormat="MM/dd/yyyy"
                className="dateFrom"
              />
              {"-"}
              <DatePicker
                selected={toDate}
                onChange={handleToDateChange}
                selectsEnd
                startDate={fromDate}
                endDate={toDate}
                placeholderText="To Date"
                dateFormat="MM/dd/yyyy"
                className="dateTo"
              />
            </div>
          </div>
        </div>
      )}

      <div className="driverRoutesTableHeader">
        <div>{driverInfoText.tableHeader1}</div>
        <div>{driverInfoText.tableHeader2}</div>
        <div>{driverInfoText.tableHeader3}</div>
        <div>{driverInfoText.tableHeader4}</div>
        <div>{driverInfoText.tableHeader5}</div>
        <div className="mapIconDiv">{driverInfoText.tableHeader6}</div>
      </div>
      <div className="driverRoutes">
        {/* Render only if isDriverRoutes is true (Current Deliveries) */}
        {!isDriverRoutes && (
          <>
            {historyStatus === "loading" && <div className="statusNotifications">Loading...</div>}
            {historyStatus === "error" && <div className="statusNotifications">{historyDeliveriesState.error}</div>}

            {historyStatus === "succeeded" &&
              historyDeliveries.map((result, index) => (
                <DriverRoute
                  key={index}
                  orderNumber={result.p_id}
                  date={new Date(result.p_order_date).toLocaleDateString()}
                  time={new Date(result.p_order_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  address={result.p_dropoff_address}
                  status={result.p_status}
                />
              ))}
          </>
        )}

        {/* Render only if isDriverRoutes is false (Delivery History) */}
        {isDriverRoutes && (
          <>
            {deliveryStatus === "loading" && (
              <div className="statusNotifications">
                <span className="blackLoader"></span>
              </div>
            )}
            {deliveryStatus === "error" && <div className="statusNotifications">{currentDeliveriesState.error}</div>}
            {deliveryStatus === "succeeded" &&
              deliveries.map((result, index) => (
                <DriverRoute
                  key={index}
                  orderNumber={result.p_id}
                  date={new Date(result.p_order_date).toLocaleDateString()}
                  time={new Date(result.p_order_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  address={`${result.p_pickup_address_name} - ${result.p_dropoff_address_name}`}
                  status={result.p_status}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
}
