import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchDepartments = createAsyncThunk("addresses/getDepartments", async (data = {}, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;
  const { filters } = data;

  const queryParams = new URLSearchParams();

  // Append department filter if present and not empty
  if (filters && filters.department_name !== undefined && filters.department_name !== "") {
    queryParams.append("department_name", filters.department_name);
  }

  // Append search query if present and not empty
  if (filters && filters.searchQuery !== undefined && filters.searchQuery !== "") {
    queryParams.append("department_name", filters.searchQuery);
  }

  if (data && data.company_id) {
    queryParams.append("company_id", data.company_id);
  }

  // Append other filters
  if (filters && filters.sort_by) queryParams.append("sort_by", filters.sort_by);
  if (filters && filters.sort_order) queryParams.append("sort_order", filters.sort_order);
  if (data && data.page) queryParams.append("page", data.page);
  if (filters && filters.per_page) queryParams.append("per_page", filters.per_page);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${apiUrl}/get_department?${queryParams.toString()}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const errorText = await response.text();
        const errorObj = JSON.parse(errorText);
        errorMessage = errorObj.error || errorMessage;
      } catch (e) {
        errorMessage = errorText || errorMessage;
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Fetching departments failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
