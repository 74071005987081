// thunks/getDeliveryHistoryThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchDeliveryHistory = createAsyncThunk(
  "deliveries/fetchDeliveryHistory",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    // Build the query parameters dynamically
    const queryParams = new URLSearchParams();
    if (data.driver_id) queryParams.append("driver_id", data.driver_id);
    if (data.from_date) queryParams.append("from_date", data.from_date);
    if (data.to_date) queryParams.append("to_date", data.to_date);
    if (data.address) queryParams.append("address", data.address);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/history_deliveries?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.msg || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching delivery history failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
