import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./DeleteCustomerAddress.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { deleteCustomerAddressText } from "../../translations";
import { deleteCustomer } from "../../redux/customer/thunks/deleteCustomerThunk";
import { deleteDepartment } from "../../redux/department/thunks/deleteDepartmentThunk";
import { resetDeleteCustomerStatus } from "../../redux/customer/slices/deleteCustomerSlice";
import { resetDeleteDepartmentStatus } from "../../redux/department/slices/deleteDepartmentSlice";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleDeleteCustomerAddressModal,
  setErrorMsg,
  onErrorMessage,
  setSuccessMsg,
  onDeleteConfirm,
  setIsDeleteConfirmShown,
  setIsModalsBgShown,
  setIsErrorMsg,
} from "../../redux/globalFunctions/globalFnSlice";

export function DeleteCustomerAddress() {
  const { selectedDataForEdit, isCustomerActive } = useSelector((state) => state.global);
  const deleteCustomerState = useSelector((state) => state.deleteCustomer);
  const deleteDepartmentState = useSelector((state) => state.deleteDepartment);

  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  const handleDeleteCustomer = () => {
    dispatch(deleteCustomer({ customer_id: selectedDataForEdit.c_id }));
  };

  const handleDeleteLaundry = () => {
    dispatch(deleteDepartment({ department_id: selectedDataForEdit.d_id }));
  };

  const handleDelete = isCustomerActive ? handleDeleteCustomer : handleDeleteLaundry;

  useEffect(() => {
    // Handler for deleteCustomer statuses
    if (hasMounted.current) {
      if (deleteCustomerState.status === "success") {
        dispatch(toggleDeleteCustomerAddressModal());
        dispatch(setSuccessMsg(deleteCustomerState.message));
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDeleteCustomerStatus());
        }, 1500);
      } else if (deleteCustomerState.status === "error") {
        dispatch(toggleDeleteCustomerAddressModal());
        dispatch(setErrorMsg(deleteCustomerState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetDeleteCustomerStatus());
        }, 1500);
      }
    }
  }, [deleteCustomerState.status, dispatch]);

  // Handler for deleteDepartment statuses
  useEffect(() => {
    if (hasMounted.current) {
      if (deleteDepartmentState.status === "success") {
        dispatch(toggleDeleteCustomerAddressModal());
        dispatch(setSuccessMsg(deleteDepartmentState.message));
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDeleteDepartmentStatus());
        }, 1500);
      } else if (deleteDepartmentState.status === "error") {
        dispatch(toggleDeleteCustomerAddressModal());
        dispatch(setErrorMsg(deleteDepartmentState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetDeleteDepartmentStatus());
        }, 1500);
      }
    }
  }, [deleteDepartmentState.status, dispatch]);

  const isStatusLoading = deleteCustomerState.status === "loading" || deleteDepartmentState.status === "loading";

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(toggleDeleteCustomerAddressModal()));
  useClickOutsideClose(modalRef, () => dispatch(toggleDeleteCustomerAddressModal()));

  return (
    <div className="deleteCustomerAddress" ref={modalRef}>
      <div className="deleteCustomerAddressHeading">
        <h6>{isCustomerActive ? deleteCustomerAddressText.headerText : deleteCustomerAddressText.laundryHeader}</h6>
        <span className="closeBtn">
          <img src={CloseIcon} alt="Close icon" onClick={() => dispatch(toggleDeleteCustomerAddressModal())} />
        </span>
      </div>
      <div>
        {deleteCustomerAddressText.areYouSureText}{" "}
        <span className="addressText">
          {isCustomerActive ? selectedDataForEdit.c_address : selectedDataForEdit.d_name}
        </span>
      </div>
      <div>
        {isCustomerActive
          ? deleteCustomerAddressText.byConfirmingThisText
          : deleteCustomerAddressText.byConfirmingThisTextLaundry}
      </div>
      <div className="deleteCustomerBtns">
        <button className="cancelButton" onClick={() => dispatch(toggleDeleteCustomerAddressModal())}>
          {deleteCustomerAddressText.cancelBtn}
        </button>
        <button className="confirmButton" onClick={handleDelete} disabled={isStatusLoading}>
          {isStatusLoading ? <span className="loader"></span> : deleteCustomerAddressText.confirmBtn}
        </button>
      </div>
    </div>
  );
}
