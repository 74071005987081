import CircleWithDotBlue from "../images/orderInformation/circleWithDotIconBlue.svg";
import CircleWithDotGray from "../images/orderInformation/circleWithDotIconGray.svg";
import AddressIconBlue from "../images/orderInformation/addressIconBlue.svg";
import AddressIconGray from "../images/orderInformation/addressIconGray.svg";
import AddressIconRed from "../images/orderInformation/addressIconRed.svg";

export function getStatusStyles(status, date, time) {
  switch (status) {
    case "Waiting":
      return {
        icon1: CircleWithDotGray,
        icon2: CircleWithDotGray,
        icon3: AddressIconGray,
        lineClass: "gray",
        statusText1: "Waiting",
        statusText2: "",
        statusText3: "",
        smallText1: "Waiting",
        smallText3: "Delivered",
      };
    case "Shipped":
      return {
        icon1: CircleWithDotBlue,
        icon2: CircleWithDotGray,
        icon3: AddressIconGray,
        lineClass: "blue",
        statusText1: "Shipped",
        statusText2: "",
        statusText3: "",
        smallText1: "Shipped",
        smallText3: "Delivered",
      };
    case "On the way":
      return {
        icon1: CircleWithDotBlue,
        icon2: CircleWithDotBlue,
        icon3: AddressIconGray,
        lineClass: "blue",
        statusText1: "Done",
        statusText2: `${date} ${time}`,
        statusText3: "",
        smallText1: "Shipped",
        smallText3: "Delivered",
      };
    case "Delivered":
      return {
        icon1: CircleWithDotBlue,
        icon2: CircleWithDotBlue,
        icon3: AddressIconBlue,
        lineClass: "blue",
        statusText1: "Done",
        statusText2: `Done`,
        statusText3: `${date} ${time}`,
        smallText1: "Shipped",
        smallText3: "Delivered",
      };
    case "Cancelled":
      return {
        icon1: CircleWithDotBlue,
        icon2: CircleWithDotBlue,
        icon3: AddressIconRed,
        lineClass: "blue",
        statusText1: "Done",
        statusText2: `${date} ${time}`,
        statusText3: "Cancelled",
        smallText1: "Shipped",
        smallText3: "Cancelled",
      };
    default:
      return {
        icon1: CircleWithDotGray,
        icon2: CircleWithDotGray,
        icon3: AddressIconGray,
        lineClass: "gray",
        statusText1: "",
        statusText2: "",
        statusText3: "",
        smallText1: "Shipped",
        smallText3: "Delivered",
      };
  }
}
