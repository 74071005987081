// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addDriverModal {
  width: 30%;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
}

.addDriverHeading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
}

.addDriverHeading h6 {
  margin: 0;
  color: #1b1f3ba6;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.closeAddDriverBtn {
  cursor: pointer;
}

.addDriverForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #676c7e;
}

.addDriverForm input {
  border: none;
}

.saveDriverBtn {
  background-color: #3082f6;
  height: 44px;
  border-radius: 8px;
  padding: 0 16px;
  border: none;
  color: white;
  cursor: pointer;
}

.saveDriverBtn:disabled {
  background-color: #8cb6f1;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/AddNewDriver.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".addDriverModal {\r\n  width: 30%;\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n}\r\n\r\n.addDriverHeading {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding-bottom: 32px;\r\n}\r\n\r\n.addDriverHeading h6 {\r\n  margin: 0;\r\n  color: #1b1f3ba6;\r\n  font-size: 20px;\r\n  font-weight: 600;\r\n  line-height: 26px;\r\n}\r\n\r\n.closeAddDriverBtn {\r\n  cursor: pointer;\r\n}\r\n\r\n.addDriverForm {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 24px;\r\n  color: #676c7e;\r\n}\r\n\r\n.addDriverForm input {\r\n  border: none;\r\n}\r\n\r\n.saveDriverBtn {\r\n  background-color: #3082f6;\r\n  height: 44px;\r\n  border-radius: 8px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.saveDriverBtn:disabled {\r\n  background-color: #8cb6f1;\r\n  cursor: not-allowed;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
