import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./DeleteModal.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { deleteModalText } from "../../translations";
import { cancelPackage } from "../../redux/package/thunks/cancelPackageThunk";
import { deleteDriver } from "../../redux/driver/thunks/deleteDriverThunk";
import { resetCancelPackageStatus } from "../../redux/package/slices/cancelPackageSlice";
import { resetDriverDeleteStatus } from "../../redux/driver/slices/deleteDriverSlice";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  onDelete,
  onDeleteConfirm,
  setErrorMsg,
  onErrorMessage,
  setSuccessMsg,
  setIsDeleteConfirmShown,
  setIsModalsBgShown,
  setIsErrorMsg,
} from "../../redux/globalFunctions/globalFnSlice";

export function DeleteModal() {
  const { isWithHistory, selectedDataForEdit } = useSelector((state) => state.global);
  const cancelDeliveryState = useSelector((state) => state.cancelDelivery);
  const deleteDriverState = useSelector((state) => state.deleteDriver);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (!isWithHistory) {
      dispatch(cancelPackage({ package_id: selectedDataForEdit.package_id }));
    } else {
      dispatch(deleteDriver({ u_id: selectedDataForEdit.u_id }));
    }
  };

  // Handler for cancelDelivery statuses
  useEffect(() => {
    if (cancelDeliveryState.status === "success") {
      dispatch(onDelete());
      dispatch(onDeleteConfirm());
      dispatch(setSuccessMsg(cancelDeliveryState.package.msg));
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
    } else if (cancelDeliveryState.status === "error") {
      dispatch(onDelete());
      dispatch(setIsErrorMsg(true));
      dispatch(setErrorMsg(cancelDeliveryState.error));
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
      }, 1500);
    }
    setTimeout(() => {
      dispatch(resetCancelPackageStatus());
    }, 500);
  }, [cancelDeliveryState.status, dispatch]);

  // Handler for deleteDriver statuses
  useEffect(() => {
    if (deleteDriverState.status === "success") {
      dispatch(onDelete());
      dispatch(onDeleteConfirm());
      dispatch(setSuccessMsg(deleteDriverState.message));
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
      }, 1500);
    } else if (deleteDriverState.status === "error") {
      dispatch(onDelete());
      dispatch(setIsErrorMsg(true));
      dispatch(setErrorMsg(deleteDriverState.error));
      dispatch(onErrorMessage());
      setTimeout(() => {
        dispatch(setIsDeleteConfirmShown(false));
        dispatch(setIsModalsBgShown(false));
        dispatch(setIsErrorMsg(false));
        dispatch(setErrorMsg(""));
      }, 1500);
    }

    setTimeout(() => {
      dispatch(resetDriverDeleteStatus());
    }, 500);
  }, [deleteDriverState.status, dispatch]);

  const isStatusLoading = cancelDeliveryState.status === "loading" || deleteDriverState.status === "loading";

  const modalRef = useRef(null);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(onDelete()));
  useClickOutsideClose(modalRef, () => dispatch(onDelete()));

  return (
    <div className="deleteDeliveryModal" ref={modalRef}>
      <div className="topDiv">
        <h6>{isWithHistory ? deleteModalText.headerTextDelete : deleteModalText.headerTextCancel}</h6>
        <span className="closeDeleteBtn">
          <img src={CloseIcon} alt="Close icon" onClick={() => dispatch(onDelete())} />
        </span>
      </div>
      <div className="confirmOrderNumber">
        {isWithHistory ? <>{deleteModalText.areYouSureText} </> : <>{deleteModalText.deliveryAreYouSureText} </>}
        <span className="idSpan">
          {isWithHistory ? selectedDataForEdit.u_firstname : selectedDataForEdit.package_id}?
        </span>
      </div>
      <p>{isWithHistory ? deleteModalText.driverText : deleteModalText.deliveryText}</p>
      <div className="deleteDeliveryBtns">
        <button className="cancelDeleteBtn" onClick={() => dispatch(onDelete())}>
          {deleteModalText.cancelBtn}
        </button>
        <button className="confirmDeleteBtn" onClick={handleButtonClick} disabled={isStatusLoading}>
          {isStatusLoading ? <span className="loader"></span> : deleteModalText.confirmBtn}
        </button>
      </div>
    </div>
  );
}
