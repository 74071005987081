import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { registerUser } from "../../redux/driver/thunks/registerDriverThunk";
import { validateToken } from "../../redux/driver/thunks/validateDriverInvitationThunk";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import "./Register.css";
import { resetRegisterState } from "../../redux/driver/slices/registerDriverSlice";
import translations from "../../translations.json";

export function Register() {
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const dispatch = useDispatch();
  const registerSelector = useSelector((state) => state.register);
  const validateTokenSelector = useSelector((state) => state.validateToken);
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    dispatch(resetRegisterState());
  }, []);

  useEffect(() => {
    if (registerSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetRegisterState());
      }, 2000);
    } else if (registerSelector.status === "success") {
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }
  }, [registerSelector.status]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get("email");
    const phonenumberParam = params.get("phonenumber");
    const tokenParam = params.get("token");

    if (emailParam) setEmail(emailParam);
    if (phonenumberParam) setPhonenumber(phonenumberParam);
    if (tokenParam) {
      setToken(tokenParam);
      dispatch(validateToken(tokenParam));
    } else {
      navigate("/login");
    }
  }, [location, dispatch, navigate]);

  useEffect(() => {
    if (
      validateTokenSelector.status === "error" &&
      validateTokenSelector.isValid === false
    ) {
      navigate("/login");
    }
  }, [validateTokenSelector, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("token", token);
    dispatch(registerUser(Object.fromEntries(formData)));
  };

  return (
    <section className="registerContainer">
      <div className="logo">
        <img src={Logo} alt={translations.registerPage.iconAlts.logoAlt} />
      </div>
      <div className="formBg">
        <img
          className="background"
          src={Background}
          alt={translations.registerPage.iconAlts.formBgAlt}
        />
        <div className="flags">
          <div className={!isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={NorwayFlag}
              alt={translations.registerPage.iconAlts.norwayFlagAlt}
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={EnglishFlag}
              alt={translations.registerPage.iconAlts.englishFlagAlt}
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>

        <form className="registerForm" onSubmit={handleSubmit}>
          <h2>{translations.registerPage.headerText}</h2>
          <div className="inputs">
            <div className="input">
              <label htmlFor="email">
                {translations.registerPage.form.email}
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input">
              <label htmlFor="password">
                {translations.registerPage.form.password}
              </label>
              <input type="password" name="password" required />
            </div>
            <div className="input">
              <label htmlFor="confirm_password">
                {translations.registerPage.form.confirmPassword}
              </label>
              <input type="password" name="confirm_password" required />
            </div>
            <div className="input">
              <label htmlFor="first_name">
                {translations.registerPage.form.firstname}
              </label>
              <input type="text" name="first_name" required />
            </div>
            <div className="input">
              <label htmlFor="last_name">
                {translations.registerPage.form.lastname}
              </label>
              <input type="text" name="last_name" required />
            </div>
            <div className="input">
              <label htmlFor="phone_number">
                {translations.registerPage.form.phone}
              </label>
              <input
                type="text"
                name="phone_number"
                value={phonenumber}
                onChange={(e) => setPhonenumber(e.target.value)}
                required
              />
            </div>
            <button
              className="registerBtn"
              type="submit"
              disabled={registerSelector.status === "loading"}
            >
              {translations.registerPage.registerBtn}
            </button>
          </div>
          <div className="notifications">
            {registerSelector.status === "loading" && <LoadingSpinner />}
            {registerSelector.status === "error" && (
              <ErrorMsg msg={registerSelector.error} />
            )}
            {registerSelector.status === "success" && (
              <p>{translations.registerPage.successMsg}</p>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}
