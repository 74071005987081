// slices/getCurrentDeliveriesSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchCurrentDeliveries } from "../thunks/getCurrentDeliveriesThunk";

const getCurrentDeliveriesReducer = createSlice({
  name: "currentDeliveries",
  initialState: {
    deliveries: [],
    total_count: 0,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
  },
  reducers: {
    resetCurrentDeliveriesState(state) {
      state.deliveries = [];
      state.status = "idle";
      state.error = null;
      state.total_count = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentDeliveries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCurrentDeliveries.fulfilled, (state, action) => {
        state.deliveries = action.payload.packages; // Adjust according to your API response
        state.total_count = action.payload.total_count;
        state.status = "succeeded";
        state.error = null; // Reset error state upon success
      })
      .addCase(fetchCurrentDeliveries.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload; // Payload here will be the error message
      });
  },
});

export const { resetCurrentDeliveriesState } = getCurrentDeliveriesReducer.actions;
export default getCurrentDeliveriesReducer.reducer;
