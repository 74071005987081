import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchDeliveries = createAsyncThunk("deliveries/fetchDeliveries", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const queryParams = new URLSearchParams();
  if (data.filters) {
    Object.keys(data.filters).forEach((key) => {
      if (data.filters[key]) {
        queryParams.append(key, data.filters[key]);
      }
    });
  }
  if (data.package_id) queryParams.append("package_id", data.package_id);
  if (data.sort_by) queryParams.append("sort_by", data.sort_by);
  if (data.sort_order) queryParams.append("sort_order", data.sort_order);
  if (data.page) queryParams.append("page", data.page);
  if (data.per_page) queryParams.append("per_page", data.per_page);

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(`${apiUrl}/get_delivery?${queryParams.toString()}`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const errorText = await response.text();
        const errorObj = JSON.parse(errorText);
        errorMessage = errorObj.error || errorMessage;
      } catch (e) {
        errorMessage = errorText || errorMessage;
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Fetching deliveries failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
