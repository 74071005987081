// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headDiv {
  color: #3082f6;
  font-weight: 600;
  line-height: 23.4px;
  font-size: 18px;
  margin-bottom: 10px;
}

.successNotification {
  width: 500px;
  background-color: #ddeefd;
  padding: 20px 32px;
  border-radius: 8px;
  gap: 16px;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/SuccessNotification.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,aAAa;AACf","sourcesContent":[".headDiv {\r\n  color: #3082f6;\r\n  font-weight: 600;\r\n  line-height: 23.4px;\r\n  font-size: 18px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.successNotification {\r\n  width: 500px;\r\n  background-color: #ddeefd;\r\n  padding: 20px 32px;\r\n  border-radius: 8px;\r\n  gap: 16px;\r\n  display: flex;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
