export const headerText = {
  searchPlaceholder: "Search for anything",
};

export const dashboardText = {
  welcomeUsername: "Hello",
  welcomeText: "How are you today?",
  btnText: "Add Package for Delivery",
  tableHeader1: "Driver",
  tableHeader2: "Order",
  tableHeader3: "Date",
  tableHeader4: "Address",
  tableHeader5: "Status",
};

export const deliveriesText = {
  headerText: "Deliveries",
  btnText: "Add New Package for Delivery",
  filterBy: "Filter by",
  customerSelect: "All Customers",
  driversSelect: "All Drivers",
  statusesSelect: "All Statuses",
  resetSelectsBtn: "Reset all",
  tableHeader1: "Order",
  tableHeader2: "Customer",
  tableHeader3: "Pickup address",
  tableHeader4: "Dropoff address",
  tableHeader5: "Date",
  tableHeader6: "Time",
  tableHeader7: "Driver",
  tableHeader8: "Status",
  tableHeader9: "Action",
};

export const driversText = {
  headerText: "Drivers",
  btnText: "Invite New Driver",
  filterBy: "Sort by ASC or DESC",
  namesSelect: "All names",
  vehiclesSelect: "All vehicles",
  resetSelectsBtn: "Reset all",
  tableHeader1: "ID",
  tableHeader2: "Name",
  tableHeader3: "Phone",
  tableHeader4: "Vehicle",
  tableHeader5: "Location",
  tableHeader6: "Routes",
  tableHeader7: "Action",
};

export const addressesText = {
  customerBtn: "Customer Addresses",
  laundryBtn: "Pickup Addresses",
  newAddressBtn: "Add New Address",
  filterBy: "Filter by",
  customerSelect: "All Customers",
  departmentSelect: "All Departments",
  resetSelectsBtn: "Reset filter",
  tableHeaderCustomer: "Customer",
  tableHeaderLaundry: "Laundry",
  tableHeader2: "Address",
  tableHeader3: "Contact phone",
  tableHeader4: "Edit",
  tableHeader5: "Delete",
  errorFetchingData: "No more data found",
};

// Text for modals.

export const addCustomerAddressText = {
  headerForAdd: "Add Customer Address",
  headerForEdit: "Edit Customer Address",
  customerSelect: "Customer",
  laundrySelect: "Laundry",
  addressSelect: "Address",
  phoneSelect: "Contact phone",
  btnText: "Save",
  laundryHeaderAdd: "Add Laundry Address",
  laundryHeaderEdit: "Edit Laundry Address",
  countrySelect: "Country",
  citySelect: "City",
  postalCodeSelect: "Postal code",
};

export const addNewDriverText = {
  headerForAdd: "Invite Driver",
  headerForEdit: "Edit Driver",
  nameSelect: "First Name",
  lastnameSelect: "Last Name",
  usernameSelect: "Username",
  vehicleSelect: "Vehicle",
  emailSelect: "Email",
  phoneSelect: "Phone",
  btnText: "Save",
};

export const deleteConfirmationText = {
  headerText: "Info",
  deletedText: "Successfully deleted",
  canceledText: "Delivery order successfully cancelled",
};

export const deleteCustomerAddressText = {
  headerText: "Delete Customer Address",
  laundryHeader: "Delete Laundry Address",
  areYouSureText: "Are you sure you want to delete",
  byConfirmingThisText:
    "By confirming this your created customer address will be deleted. You will not be able to cancel this action",
  byConfirmingThisTextLaundry:
    "By confirming this your created laundry address will be deleted. You will not be able to cancel this action",
  cancelBtn: "Cancel",
  confirmBtn: "Confirm",
};

export const deleteModalText = {
  headerTextDelete: "Delete Driver",
  headerTextCancel: "Cancel Delivery",
  driverText: "By confirming this your created driver will be deleted. You will not be able to cancel this action.",
  deliveryText:
    "By confirming this your created delivery order will be cancelled. You will need to create a new order in case you cancelled the order by mistake.",
  areYouSureText: "Are you sure you want to delete",
  deliveryAreYouSureText: "Are you sure you want to cancel",
  cancelBtn: "Cancel",
  confirmBtn: "Confirm",
};

export const driverInfoText = {
  headerTextRoutes: "Routes:",
  headerTextHistory: "Delivery history:",
  filterBy: "Filter by",
  addressSelect: "Address",
  resetSelectsBtn: "Reset all",
  tableHeader1: "Order",
  tableHeader2: "Date",
  tableHeader3: "Time",
  tableHeader4: "Address",
  tableHeader5: "Status",
  tableHeader6: "Map",
};

export const driverLocationText = {
  headerText: "Order:",
  informationHeader: "Order information",
  text1: "Date:",
  text2: "Time:",
  text3: "Address:",
  text4: "Shipped",
  text5: "On the way",
  text6: "Delivered",
};

export const editDeliveryText = {
  headerTextEdit: "Edit Delivery Order",
  headerTextAdd: "Add Package for Delivery",
  customerSelect: "Customer",
  driversSelect: "Driver",
  pickupSelect: "Pickup address",
  dropoffSelect: "Dropoff address",
  dateInput: "Date",
  timeInput: "Time",
  countryInput: "Country",
  cityInput: "City",
  postalCodeInput: "Postal code",
  cancelBtn: "Cancel Delivery",
  saveBtn: "Save",
};

export const sidebarText = {
  menuLink1: "Dashboard",
  menuLink2: "Deliveries",
  menuLink3: "Drivers",
  menuLink4: "Addresses",
};

export const orderInformationText = {
  driverName: "Driver:",
  orderNumber: "Order:",
  orderInformationHeader: "Order information",
  text1: "Date:",
  text2: "Time:",
  text3: "Address:",
  text4: "Shipped",
  text5: "On the way",
  text6: "Delivered",
};
