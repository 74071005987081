import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { fetchDeliveriesByDate } from "../../redux/package/thunks/getDeliveriesByDateThunk";
import PlusSignIcon from "../../images/dashboard/plusSignIcon.svg";
import DeliveryVanIcon from "../../images/dashboard/deliveryVan.svg";
import CalendarIcon from "../../images/dashboard/calendarIcon.svg";
import "./Dashboard.css";
import { DashboardTable } from "../UI/DashboardTable";
import { dashboardText } from "../../translations";
import { OrderInformation } from "../UI/OrderInformation";
import { fetchDeliveries } from "../../redux/package/thunks/getDeliveryThunk";
import { formatDateString } from "../../helperFunctions/formatDate";
import { onEdit, setIsEditMode } from "../../redux/globalFunctions/globalFnSlice";

export function Dashboard() {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastPageDeliveries, setLastPageDeliveries] = useState([]);
  const [isLastPageFetched, setIsLastPageFetched] = useState(false);
  const [isPackageCreated, setIsPackageCreated] = useState(true);

  // Variables for redux state.
  const deliveriesByDate = useSelector((state) => state.deliveriesByDate.deliveries);
  const statusByDate = useSelector((state) => state.deliveriesByDate.status);
  const errorByDate = useSelector((state) => state.deliveriesByDate.error);
  const searchQuery = useSelector((state) => state.search.query);
  const deliveriesState = useSelector((state) => state.deliveries);
  const firstname = useSelector((state) => state.user.firstname);
  const createPackageState = useSelector((state) => state.createDelivery);

  const initialFilters = {
    customer_name: "",
    driver_name: "",
    status: "",
    date: "",
  };

  // Fetch deliveries by selected date
  useEffect(() => {
    const formattedDate = format(selectedDate, "dd/MM/yyyy");
    dispatch(fetchDeliveriesByDate(formattedDate));
    dispatch(fetchDeliveries({ initialFilters, page: 1 }));
  }, [dispatch, selectedDate]);

  // Fetch last page if deliveriesByDate fetch fails
  useEffect(() => {
    if (statusByDate === "error" && deliveriesState.total_count && !isLastPageFetched && isPackageCreated === true) {
      const lastPage = Math.ceil(deliveriesState.total_count / 10);
      dispatch(fetchDeliveries({ initialFilters, page: lastPage })).then((action) => {
        if (action.payload) {
          setLastPageDeliveries(action.payload.deliveries);
          setIsLastPageFetched(true);
        }
      });
    }
  }, [statusByDate, deliveriesState.total_count, dispatch, isLastPageFetched, isPackageCreated]);

  // Refetch deliveries when createPackageState.status is "success"
  useEffect(() => {
    if (createPackageState.status === "success") {
      const formattedDate = format(selectedDate, "dd/MM/yyyy");
      dispatch(fetchDeliveriesByDate(formattedDate));
      setIsPackageCreated(true);
      setIsLastPageFetched(false);
    }
  }, [createPackageState.status]);

  // Function to handle click on a delivery row
  const handleDeliveryClick = (index) => {
    setSelectedIndex(index); // Set the selected delivery index
  };

  // Function to close the OrderInformation component
  const handleCloseOrderInformation = () => {
    setSelectedIndex(null); // Reset selectedIndex state
  };

  // Function to handle previous arrow click
  const handlePrevDelivery = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  // Function to handle next arrow click
  const handleNextDelivery = () => {
    if (selectedIndex < deliveriesResult.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  // Filter deliveries based on search query
  const filteredDeliveries = deliveriesByDate.filter(
    (delivery) =>
      (delivery.driver_name && delivery.driver_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (delivery.dropoff_address_name !== null &&
        delivery.dropoff_address_name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const filteredLastPageDeliveries = lastPageDeliveries.filter(
    (delivery) =>
      (delivery.driver_name && delivery.driver_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (delivery.dropoff_address_name !== null &&
        delivery.dropoff_address_name.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const deliveriesResult = statusByDate === "error" ? filteredLastPageDeliveries : filteredDeliveries;

  return (
    <section className="dashboard">
      <div className="dashboardTopDiv">
        <div className="bgDiv">
          <div className="contentLeft">
            <h4>{`${dashboardText.welcomeUsername} ${firstname}`}</h4>
            <p>{dashboardText.welcomeText}</p>
            <button
              onClick={() => {
                dispatch(setIsEditMode(false));
                dispatch(onEdit());
              }}
            >
              <img src={PlusSignIcon} alt="plus sign" />
              {dashboardText.btnText}
            </button>
          </div>
          <div className="contentRight">
            <img src={DeliveryVanIcon} alt="Delivery van" />
          </div>
        </div>
      </div>
      <div className="deliveries-order-info">
        <div className="deliveries">
          <div className="deliveriesFirstDiv">
            <div className="firstDivContent">
              <h6>Deliveries</h6>
              <div className="calendarContainer">
                <img src={CalendarIcon} alt="Calendar icon" />
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  className="datePicker"
                />
              </div>
            </div>
          </div>
          {statusByDate === "error" && (
            <div className="lastPageResults">
              No deliveries found for {formatDateString(selectedDate)}. Showing last results created.
            </div>
          )}
          <div className="table">
            <div className="thead">
              <div className="tableHeaders">{dashboardText.tableHeader1}</div>
              <div className="tableHeaders">{dashboardText.tableHeader2}</div>
              <div className="tableHeaders">{dashboardText.tableHeader3}</div>
              <div className="tableHeaders">{dashboardText.tableHeader4}</div>
              <div className="tableHeaders">{dashboardText.tableHeader5}</div>
            </div>
            <div className="dashboardResultsDiv">
              {(statusByDate === "loading" || deliveriesState.status === "loading") && (
                <div className="statusLoad">
                  Loading... <span className="blackLoader"></span>
                </div>
              )}
              {statusByDate === "error" && deliveriesState.status === "error" && (
                <div className="statusError">{errorByDate}</div>
              )}

              {(statusByDate === "succeeded" || deliveriesState.status === "succeeded") &&
                deliveriesResult &&
                deliveriesResult.map((result, index) => (
                  <DashboardTable
                    key={index}
                    order_number={result.package_id || result.delivery_id}
                    customer={result.customer_name}
                    pickup_address={result.pickup_address_name}
                    dropoff_address={result.dropoff_address_name}
                    date={result.order_date}
                    time={result.order_time}
                    driver_name={result.driver_name || result.driver.name}
                    status={result.status}
                    onClick={() => handleDeliveryClick(index)}
                  />
                ))}
            </div>
          </div>
        </div>
        {selectedIndex !== null && deliveriesResult[selectedIndex] && (
          <OrderInformation
            driver_name={deliveriesResult[selectedIndex].driver_name || deliveriesResult[selectedIndex].driver.name}
            order_number={deliveriesResult[selectedIndex].delivery_id ?? deliveriesResult[selectedIndex].package_id}
            date={deliveriesResult[selectedIndex].order_date}
            time={deliveriesResult[selectedIndex].order_time}
            address={deliveriesResult[selectedIndex].dropoff_address_name}
            shipping_status={deliveriesResult[selectedIndex].status}
            selectedIndex={selectedIndex}
            deliveries={deliveriesResult}
            onClose={handleCloseOrderInformation}
            onPrev={handlePrevDelivery}
            onNext={handleNextDelivery}
          />
        )}
      </div>
    </section>
  );
}
