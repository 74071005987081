// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.driver {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 16px;
  color: #676c7e;
  line-height: 24px;
  width: 100%;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.mapIconDiv img {
  cursor: pointer;
}

.driver div {
  width: 120px;
  word-wrap: break-word;
}

.driver :nth-child(n + 5):nth-child(-n + 6) {
  display: flex;
  justify-content: center;
}
.driver :nth-child(n + 7) {
  display: flex;
  justify-content: flex-end;
}

.driver button {
  border: none;
  background-color: transparent;
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: #3082f6;
  }
}

.driver img {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DriversTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,eAAe;;EAEf;IACE,cAAc;EAChB;AACF;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".driver {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  padding: 12px 16px;\r\n  color: #676c7e;\r\n  line-height: 24px;\r\n  width: 100%;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.mapIconDiv img {\r\n  cursor: pointer;\r\n}\r\n\r\n.driver div {\r\n  width: 120px;\r\n  word-wrap: break-word;\r\n}\r\n\r\n.driver :nth-child(n + 5):nth-child(-n + 6) {\r\n  display: flex;\r\n  justify-content: center;\r\n}\r\n.driver :nth-child(n + 7) {\r\n  display: flex;\r\n  justify-content: flex-end;\r\n}\r\n\r\n.driver button {\r\n  border: none;\r\n  background-color: transparent;\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  padding: 0;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    color: #3082f6;\r\n  }\r\n}\r\n\r\n.driver img {\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
