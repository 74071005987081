// thunks/searchThunk.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const searchItems = createAsyncThunk(
  "search/searchItems",
  async ({ query }, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const queryParams = new URLSearchParams();
    queryParams.append("query", query);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/search?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.msg || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching search results failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
