import React, { useEffect, useRef, useState } from "react";
import Map, { Marker, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveries } from "../../../redux/package/thunks/getDeliveryThunk";
import classes from "./AllDriversMap.module.css";
import CloseIcon from "../../../images/deliveries/deliveriesModals/xIcon.svg";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import {
  setIsEditModalShown,
  setIsEditMode,
  setIsMapShown,
  setIsModalsBgShown,
  setSelectedDriverInfo,
} from "../../../redux/globalFunctions/globalFnSlice";
import { useLocation } from "react-router-dom";

export function AllDriversMap() {
  const [coordinatesList, setCoordinatesList] = useState([]);
  const [viewState, setViewState] = useState({
    zoom: 8,
  });

  const dispatch = useDispatch();
  const delivery = useSelector((state) => state.deliveries);
  const modalRef = useRef();
  const location = useLocation();

  function handleCloseModal() {
    if (location.pathname === "/deliveries" || location.pathname === "/dashboard") {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
      dispatch(setIsEditMode(false));
    } else {
      dispatch(setIsMapShown(false));
      dispatch(setIsModalsBgShown(false));
    }
  }

  const mapboxToken = "pk.eyJ1IjoiZGF2b3JuIiwiYSI6ImNtMDZnNjg0czB0Y3Aya3NiMHFzY2xrbjYifQ.sMnCXULu1e1vuXy6xU4cRQ";

  useEffect(() => {
    dispatch(fetchDeliveries({ page: 6 }));
  }, [dispatch]);

  const geocodeAddress = async (address) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          address
        )}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const [lng, lat] = data.features[0].center;
      return { lat, lng };
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (delivery.deliveries && delivery.deliveries.length > 0) {
        const coordsPromises = delivery.deliveries.map(async (item) => {
          const coords = await geocodeAddress(item.pickup_address_name);
          return {
            ...coords,
            driverName: item.driver.name,
            item,
          };
        });
        const coords = await Promise.all(coordsPromises);
        const validCoords = coords.filter(Boolean);
        setCoordinatesList(validCoords);

        if (validCoords.length > 0) {
          const bottomMarkerLat = validCoords[0].lat;
          setViewState((prevState) => ({
            ...prevState,
            latitude: validCoords[0].lat,
            longitude: validCoords[0].lng,
          }));
        }
      }
    };

    fetchCoordinates();
  }, [delivery]);

  const handleMarkerDragEnd = (index, event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;
    const updatedCoordinates = [...coordinatesList];
    updatedCoordinates[index] = { lat, lng };
    setCoordinatesList(updatedCoordinates);
    reverseGeocodeCoordinates(lat, lng);
  };

  const reverseGeocodeCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const address = data.features[0].place_name;
    } catch (error) {
      console.error("Error fetching the address:", error);
    }
  };

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);
  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>Map for the locations of all the drivers</h3>
        <span onClick={handleCloseModal}>
          <img src={CloseIcon} alt="Close modal icon" />
        </span>
      </div>
      <div className={classes.mapContainer}>
        <Map
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapboxToken}
          className={classes.map}
        >
          {coordinatesList.map((coordinates, index) => (
            <div key={index}>
              <Marker
                key={index}
                latitude={coordinates.lat}
                longitude={coordinates.lng}
                draggable={false}
                onDragEnd={(event) => handleMarkerDragEnd(index, event)}
                onClick={() => {
                  dispatch(setSelectedDriverInfo(coordinates.item));
                  handleCloseModal();
                }}
              />
              <Popup
                longitude={coordinates.lng}
                latitude={coordinates.lat}
                closeButton={true}
                closeOnClick={false}
                offset={35}
              >
                <p>{coordinates.driverName}</p>
              </Popup>
            </div>
          ))}
        </Map>
      </div>
    </div>
  );
}
