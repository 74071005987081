// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllDriversMap_container__OU2aM {
  width: 800px;
  height: 500px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AllDriversMap_mapContainer__93w\\+J {
  width: 100%;
  height: 100%;
}

.AllDriversMap_header__NXXzx {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AllDriversMap_header__NXXzx img {
  cursor: pointer;
}

.AllDriversMap_header__NXXzx h3 {
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/allDriversMap/AllDriversMap.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\r\n  width: 800px;\r\n  height: 500px;\r\n  background-color: rgb(255, 255, 255);\r\n  border-radius: 8px;\r\n  padding: 32px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.mapContainer {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.header {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.header img {\r\n  cursor: pointer;\r\n}\r\n\r\n.header h3 {\r\n  margin-top: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AllDriversMap_container__OU2aM`,
	"mapContainer": `AllDriversMap_mapContainer__93w+J`,
	"header": `AllDriversMap_header__NXXzx`
};
export default ___CSS_LOADER_EXPORT___;
