// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deliveryTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #676c7e;
  line-height: 24px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 16px;
  width: 95%;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000014;
    box-shadow: 0px 6px 20px 0px #00000014;
    background-color: #f6f6f6;
  }
}

.threeDotsBtn {
  background-color: transparent;
  font-size: 30px;
  font-weight: 700;
  border: none;
  cursor: pointer;

  &:hover {
    color: #3082f6;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/DeliveriesTable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV;IACE,qCAAqC;IACrC,sCAAsC;IACtC,yBAAyB;EAC3B;AACF;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;;EAEf;IACE,cAAc;EAChB;AACF","sourcesContent":[".deliveryTable {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  color: #676c7e;\r\n  line-height: 24px;\r\n  border: 1px solid #f0f0f0;\r\n  border-radius: 8px;\r\n  padding: 12px 16px;\r\n  width: 95%;\r\n  &:hover {\r\n    box-shadow: 0px 2px 4px 0px #00000014;\r\n    box-shadow: 0px 6px 20px 0px #00000014;\r\n    background-color: #f6f6f6;\r\n  }\r\n}\r\n\r\n.threeDotsBtn {\r\n  background-color: transparent;\r\n  font-size: 30px;\r\n  font-weight: 700;\r\n  border: none;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    color: #3082f6;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
