import React, { useState, useEffect } from "react";
import "./Login.css";
import Logo from "../../images/Logo.svg";
import Background from "../../images/BG.svg";
import NorwayFlag from "../../images/NorwayFlag.svg";
import EnglishFlag from "../../images/EnglishFlag.svg";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/auth/thunks/userThunk";
import { ErrorMsg } from "../../components/errorMsg/ErrorMsg";
import { LoadingSpinner } from "../../components/loadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import {
  reqPassReset,
  resetPassReq,
} from "../../redux/requestPassReset/reqPassReset";
import { resetLoginState } from "../../redux/auth/slices/userSlice";

export function Login() {
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [isForgottenPass, setIsForgottenPass] = useState(false);
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSelector = useSelector((state) => state.user);
  const passResetState = useSelector((state) => state.resetPassReq);

  // Function for resetting password
  function handlePassReset(e) {
    e.preventDefault();
    dispatch(reqPassReset({ email }));
  }

  // Function for forgotten password form
  useEffect(() => {
    if (passResetState.status === "success") {
      setIsForgottenPass(false);
    } else if (passResetState.status === "error") {
      setTimeout(() => {
        dispatch(resetPassReq());
      }, 3000);
    }
  }, [passResetState.status]);

  // Function for login form
  useEffect(() => {
    if (userSelector.status === "success") {
      navigate("/dashboard");
    } else if (userSelector.status === "error") {
      setTimeout(() => {
        dispatch(resetLoginState());
      }, 2500);
    }
  }, [userSelector.status, navigate]);

  // Function for switching to forgotten password form
  const toggleForms = () => {
    setIsLoginForm(!isLoginForm);
    setIsForgottenPass(!isForgottenPass);
  };

  // Function for logging in
  const handleSubmit = (e) => {
    e.preventDefault();
    if (userSelector.status === "idle" || userSelector.status === "error") {
      dispatch(login({ email, password }));
    }
  };

  const isStatusLoading =
    userSelector.status === "loading" || passResetState.status === "loading";
  const isStatusError =
    passResetState.status === "error" || userSelector.status === "error";
  const isBtnDisabled = isStatusLoading;

  return (
    <section className="loginContainer">
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="formBg">
        <img
          className="background"
          src={Background}
          alt="Blue form background"
        />
        <div className="flags">
          <div className={!isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={NorwayFlag}
              alt="Norway Flag"
              onClick={() => setIsEnglishLanguage(false)}
            />
          </div>
          <div className={isEnglishLanguage ? "selectedFlag" : ""}>
            <img
              src={EnglishFlag}
              alt="English Flag"
              onClick={() => setIsEnglishLanguage(true)}
            />
          </div>
        </div>

        {isLoginForm && (
          <form className="loginForm" onSubmit={handleSubmit}>
            <h2>Welcome to Washd Drive</h2>
            <div className="inputs">
              <div className="input">
                <label htmlFor="email">Email / Username</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <button className="loginBtn" type="submit" disabled={isBtnDisabled}>
              LOG IN
            </button>
            <button className="forgotPass" onClick={toggleForms}>
              Forgot password?
            </button>
            <div className="notifications">
              {isStatusLoading && <LoadingSpinner />}
              {isStatusError && <ErrorMsg msg={userSelector.error} />}
            </div>
          </form>
        )}

        {isForgottenPass && (
          <form className="forgotPassForm">
            <h2>Forgot password?</h2>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "30px" }}
            >
              <p>
                Don't worry! It happens.
                <br />
                Please enter the email associated with your account.
              </p>
              <div className="input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button
                className="resetPassBtn"
                onClick={handlePassReset}
                disabled={isBtnDisabled}
              >
                RESET PASSWORD
              </button>
            </div>
            <button className="backToLogin" onClick={toggleForms}>
              Back to login
            </button>
            <div className="notifications">
              {isStatusLoading && <LoadingSpinner />}
              {isStatusError && <ErrorMsg msg={passResetState.error} />}
            </div>
          </form>
        )}

        {passResetState.status === "success" && (
          <div className="checkEmail">
            <h2>Check your email</h2>
            <p>
              We sent a password reset link to <strong>{email}</strong>
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
