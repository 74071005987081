// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  background: linear-gradient(to right, white 10%, #3082f6 10%);
  display: flex;
}

.headerAndContent {
  width: 100%;
}

*:not(input):not(textarea):not(button):not(select) {
  outline: none;
  -webkit-user-select: none;
          user-select: none;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,aAAa;AACf;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".container {\r\n  background: linear-gradient(to right, white 10%, #3082f6 10%);\r\n  display: flex;\r\n}\r\n\r\n.headerAndContent {\r\n  width: 100%;\r\n}\r\n\r\n*:not(input):not(textarea):not(button):not(select) {\r\n  outline: none;\r\n  user-select: none;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
