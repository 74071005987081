import "./SuccessNotification.css";
import CheckIcon from "../../images/deliveries/deliveriesModals/checkIcon.svg";
import { deleteConfirmationText } from "../../translations";

export function SuccessNotificationModal({ msg }) {
  return (
    <div className="successNotification">
      <img src={CheckIcon} alt="Check icon" />
      <div>
        <div className="headDiv">{deleteConfirmationText.headerText}</div>
        <div style={{ color: "#676c7e", lineHeight: "24px" }}>{msg}</div>
      </div>
    </div>
  );
}
