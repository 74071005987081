import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddCustomerAddress.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { createDepartment } from "../../redux/department/thunks/createDepartmentThunk";
import { resetCreateDepartmentStatus } from "../../redux/department/slices/createDepartmentSlice";
import { updateDepartment } from "../../redux/department/thunks/updateDepartmentThunk";
import { resetUpdateDepartmentStatus } from "../../redux/department/slices/updateDepartmentSlice";
import MapboxComponent from "../mapboxComponent/MapboxComponent";
import { useLocation } from "react-router-dom";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import translations from "../../translations.json";
import {
  toggleAddLaundry,
  onDeleteConfirm,
  setErrorMsg,
  onErrorMessage,
  setIsEditMode,
  setIsEditModalShown,
  setIsModalsBgShown,
  setSuccessMsg,
  setIsDeleteConfirmShown,
  setIsErrorMsg,
} from "../../redux/globalFunctions/globalFnSlice";

export function AddLaundry() {
  const { isEditMode, selectedDataForEdit, addressData } = useSelector(
    (state) => state.global
  );
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);

  const dispatch = useDispatch();
  const hasMounted = useRef(false);

  const [formData, setFormData] = useState({
    d_name: "",
    d_cid: 6,
    d_phonenumber: "",
    d_address: "",
  });

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  //Update address
  const changeAddress = (newAddress) => {
    setFormData((prevData) => ({
      ...prevData,
      d_address: newAddress,
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      dispatch(
        updateDepartment({ d_id: selectedDataForEdit.d_id, ...formData })
      );
    } else {
      dispatch(createDepartment(formData));
    }
  };

  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      setFormData({
        d_name: selectedDataForEdit.d_name || "",
        //d_cid: selectedDataForEdit.d_cid || 6,
        d_phonenumber: selectedDataForEdit.d_phonenumber || "",
        d_address: selectedDataForEdit.d_address || "",
      });
    }
  }, [isEditMode, selectedDataForEdit]);

  // Handler for createDepartment status
  useEffect(() => {
    if (hasMounted.current) {
      if (createDepartmentState.status === "success") {
        dispatch(setSuccessMsg(createDepartmentState.department.msg));
        dispatch(toggleAddLaundry());
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetCreateDepartmentStatus());
        }, 1500);
      } else if (createDepartmentState.status === "error") {
        dispatch(toggleAddLaundry());
        dispatch(setIsErrorMsg(true));
        dispatch(setErrorMsg(createDepartmentState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetCreateDepartmentStatus());
        }, 1500);
      }
    }
  }, [createDepartmentState.status, dispatch]);

  // Handler for updateDepartment status
  useEffect(() => {
    if (hasMounted.current) {
      if (updateDepartmentState.status === "success") {
        dispatch(toggleAddLaundry());
        dispatch(setSuccessMsg(updateDepartmentState.department.msg));
        dispatch(onDeleteConfirm());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetUpdateDepartmentStatus());
        }, 1500);
      } else if (updateDepartmentState.status === "error") {
        dispatch(toggleAddLaundry());
        dispatch(setErrorMsg(updateDepartmentState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetUpdateDepartmentStatus());
        }, 1500);
      }
    }
  }, [updateDepartmentState.status, dispatch]);

  const isStatusLoading =
    createDepartmentState.status === "loading" ||
    updateDepartmentState.status === "loading";

  const isEditModeFields =
    formData.d_name === "" ||
    formData.d_phonenumber === "" ||
    formData.d_address === "";

  function handleCloseModal() {
    if (
      location.pathname === "/deliveries" ||
      location.pathname === "/dashboard"
    ) {
      dispatch(toggleAddLaundry());
      dispatch(setIsModalsBgShown(true));
      dispatch(setIsEditModalShown(true));
      dispatch(setIsEditMode(false));
    } else {
      dispatch(toggleAddLaundry());
    }
  }

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/deliveries" &&
      createDepartmentState.status === "success"
    ) {
      setTimeout(() => {
        dispatch(setIsModalsBgShown(true));
        dispatch(setIsEditModalShown(true));
        dispatch(setIsEditMode(false));
      }, 1500);
    }
  }, [createDepartmentState.status]);

  const modalRef = useRef(null);

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(handleCloseModal);
  useClickOutsideClose(modalRef, handleCloseModal);

  // Filtering the data to get the correct items.
  const dataForFiltering = addressData?.addressData?.[0]?.context ?? [];
  const postalCode = dataForFiltering.find((entry) =>
    entry.id.startsWith("postcode")
  );
  const country = dataForFiltering.find((entry) =>
    entry.id.startsWith("country")
  );
  const city = dataForFiltering.find((entry) => entry.id.startsWith("place"));

  const headerText = isEditMode
    ? translations.addLaundryModal.editText
    : translations.addLaundryModal.addText;

  return (
    <div className="addCustomerAddress" ref={modalRef}>
      <div className="addCustomerAddressHeading">
        <h6>{headerText}</h6>
        <span className="closeBtn" onClick={handleCloseModal}>
          <img
            src={CloseIcon}
            alt={translations.addLaundryModal.closeIconAlt}
          />
        </span>
      </div>
      <form className="addCustomerAddressForm">
        <div className="addLaundryElements">
          <div className="formElement">
            <label htmlFor="d_name">
              {translations.addLaundryModal.form.laundry}
            </label>
            <input
              type="text"
              id="d_name"
              value={formData.d_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="formElement">
            <label htmlFor="d_phonenumber">
              {translations.addLaundryModal.form.phone}
            </label>
            <input
              type="number"
              id="d_phonenumber"
              value={formData.d_phonenumber}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="addLaundryElements">
          <div className="formElement">
            <label htmlFor="c_country">
              {translations.addLaundryModal.form.country}
            </label>
            <input
              type="text"
              id="country"
              disabled
              required
              value={country?.text}
            />
          </div>
          <div className="formElement">
            <label htmlFor="c_city">
              {translations.addLaundryModal.form.city}
            </label>
            <input
              type="text"
              id="c_city"
              disabled
              required
              value={city?.text}
            />
          </div>
        </div>
        <div className="addLaundryElements">
          <div className="formElement">
            <label htmlFor="d_address">
              {translations.addLaundryModal.form.address}
            </label>
            <input
              type="text"
              id="d_address"
              value={formData.d_address}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="formElement">
            <label htmlFor="c_postalcode">
              {translations.addLaundryModal.form.postalCode}
            </label>
            <input
              type="text"
              id="c_postalcode"
              disabled
              required
              value={postalCode?.text}
            />
          </div>
        </div>
        <MapboxComponent
          address={formData.d_address}
          updateAddress={changeAddress}
        />
      </form>
      <button
        className="saveBtn"
        onClick={handleSubmit}
        disabled={isEditModeFields || isStatusLoading}
      >
        {isStatusLoading ? (
          <span className="loader"></span>
        ) : (
          translations.addLaundryModal.saveBtn
        )}
      </button>
    </div>
  );
}
