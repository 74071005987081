// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addCustomerAddress {
  width: 30%;
  max-height: 80vh;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: hidden;
}

.addCustomerAddressHeading {
  display: flex;
  justify-content: space-between;
}

.addCustomerAddressHeading h6 {
  margin: 0;
  line-height: 26px;
  color: #676c7e;
  font-size: 20px;
}

.addCustomerAddress img {
  cursor: pointer;
}

.addCustomerAddressForm {
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: #676c7e;
  overflow-y: auto;
}

.addCustomerAddressForm::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.addCustomerAddressForm {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.addCustomerAddressForm::-webkit-scrollbar {
  display: none;
}

.formElement {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 8px 16px;
}

.addCustomerAddress input,
select {
  border: none;
  background-color: transparent;
}

.addCustomerAddress select:focus,
input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/AddCustomerAddress.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;;EAEE,aAAa;AACf","sourcesContent":[".addCustomerAddress {\r\n  width: 30%;\r\n  max-height: 80vh;\r\n  background-color: white;\r\n  padding: 32px;\r\n  border-radius: 8px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n  overflow-y: hidden;\r\n}\r\n\r\n.addCustomerAddressHeading {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.addCustomerAddressHeading h6 {\r\n  margin: 0;\r\n  line-height: 26px;\r\n  color: #676c7e;\r\n  font-size: 20px;\r\n}\r\n\r\n.addCustomerAddress img {\r\n  cursor: pointer;\r\n}\r\n\r\n.addCustomerAddressForm {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n  color: #676c7e;\r\n  overflow-y: auto;\r\n}\r\n\r\n.addCustomerAddressForm::-webkit-scrollbar {\r\n  width: 0;\r\n  background: transparent;\r\n}\r\n\r\n.addCustomerAddressForm {\r\n  -ms-overflow-style: none;\r\n  scrollbar-width: none;\r\n}\r\n\r\n.addCustomerAddressForm::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n\r\n.formElement {\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 10px;\r\n  border: 1px solid #ededed;\r\n  border-radius: 12px;\r\n  padding: 8px 16px;\r\n}\r\n\r\n.addCustomerAddress input,\r\nselect {\r\n  border: none;\r\n  background-color: transparent;\r\n}\r\n\r\n.addCustomerAddress select:focus,\r\ninput:focus {\r\n  outline: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
