import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCustomer: "",
  driver: "",
  pickup: "",
  dropoff: "",
  date: "",
  time: "",
  code: "",
  city: "",
  country: "",
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetFormData: () => initialState,
  },
});

export const { setFormData, resetFormData } = formSlice.actions;
export default formSlice.reducer;
