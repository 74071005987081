import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AddNewDriver.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import { addNewDriverText } from "../../translations";
import { inviteDriver } from "../../redux/driver/thunks/inviteDriverThunk";
import { resetDriverInviteStatus } from "../../redux/driver/slices/inviteDriverSlice";
import { updateDriver } from "../../redux/driver/thunks/updateDriverThunk";
import { resetUpdateDriverStatus } from "../../redux/driver/slices/updateDriverSlice";
import { fetchVehicles } from "../../redux/vehicle/thunks/getVehicleThunk";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import {
  toggleAddNewDriverModal,
  setErrorMsg,
  setSuccessMsg,
  onDeleteConfirm,
  onErrorMessage,
  setIsErrorMsg,
  setIsModalsBgShown,
  setIsDeleteConfirmShown,
  setIsEditMode,
} from "../../redux/globalFunctions/globalFnSlice";

const FormInput = ({ id, label, value, onChange, type = "text" }) => (
  <div className="formElement">
    <label htmlFor={id}>{label}</label>
    <input type={type} id={id} required value={value} onChange={onChange} />
  </div>
);

const FormDropdown = ({ id, label, value, onChange, options, showDefaultOption }) => (
  <div className="formElement">
    <label htmlFor={id}>{label}</label>
    <select id={id} value={value} onChange={onChange}>
      {showDefaultOption && <option value="">Select a vehicle</option>} {/* Conditionally render */}
      {options.vehicles &&
        options.vehicles.length > 0 &&
        options.vehicles.map((option) => (
          <option key={option.v_id} value={option.v_id}>
            {option.v_name}
          </option>
        ))}
    </select>
  </div>
);

export function AddNewDriver() {
  const { isEditMode, selectedDataForEdit } = useSelector((state) => state.global);

  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const modalRef = useRef(null);
  const hasMounted = useRef(false);

  const updateDriverState = useSelector((state) => state.updateDriver);
  const inviteDriverState = useSelector((state) => state.inviteDriver);
  const { vehicles } = useSelector((state) => state.vehicles);

  useEffect(() => {
    dispatch(fetchVehicles());
  }, [dispatch]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  // Populate the fields if is in edit mode, if its not then fields are empty.
  useEffect(() => {
    if (isEditMode && selectedDataForEdit) {
      setFirstName(selectedDataForEdit.u_firstname || "");
      setLastName(selectedDataForEdit.u_lastname || "");
      setPhone(selectedDataForEdit.u_phonenumber || "");
      setSelectedVehicle(selectedDataForEdit.u_vehicle_id || "");
    } else {
      setFirstName("");
      setLastName("");
      setPhone("");
      setSelectedVehicle("");
    }
  }, [isEditMode, selectedDataForEdit]);

  // Function for showing notifications according to the update driver state status.
  useEffect(() => {
    if (hasMounted.current) {
      if (updateDriverState.status === "success") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setSuccessMsg(updateDriverState.driver.msg));
        dispatch(onDeleteConfirm());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetUpdateDriverStatus());
        }, 1500);
      } else if (updateDriverState.status === "error") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setErrorMsg(updateDriverState.error));
        dispatch(onErrorMessage());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(resetUpdateDriverStatus());
        }, 1500);
      }
    }
  }, [updateDriverState.status, dispatch]);

  // Function for showing notifications according to the invite driver state status.
  useEffect(() => {
    if (hasMounted.current) {
      if (inviteDriverState.status === "success") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setSuccessMsg(inviteDriverState.driver.msg));
        dispatch(onDeleteConfirm());
        dispatch(setIsEditMode(false));
        setTimeout(() => {
          dispatch(setIsDeleteConfirmShown(false));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDriverInviteStatus());
        }, 1500);
      } else if (inviteDriverState.status === "error") {
        dispatch(toggleAddNewDriverModal());
        dispatch(setIsErrorMsg(true));
        dispatch(setErrorMsg(inviteDriverState.error));
        dispatch(onErrorMessage());
        setTimeout(() => {
          dispatch(setIsErrorMsg(false));
          dispatch(setErrorMsg(""));
          dispatch(setIsModalsBgShown(false));
          dispatch(resetDriverInviteStatus());
        }, 1500);
      }
    }
  }, [inviteDriverState.status, dispatch]);

  const handleClick = () => {
    const driverData = {
      phonenumber: phone,
      email: email,
    };

    if (isEditMode) {
      dispatch(
        updateDriver({
          u_id: selectedDataForEdit.u_id,
          u_firstname: firstName,
          u_lastname: lastName,
          u_vehicle_id: selectedVehicle,
          u_phonenumber: phone,
        })
      );
    } else {
      dispatch(inviteDriver(driverData));
    }
  };

  const isStatusLoading = inviteDriverState.status === "loading" || updateDriverState.status === "loading";

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(toggleAddNewDriverModal()));
  useClickOutsideClose(modalRef, () => dispatch(toggleAddNewDriverModal()));

  return (
    <div className="addDriverModal" ref={modalRef}>
      <div className="addDriverHeading">
        <h6>{isEditMode ? addNewDriverText.headerForEdit : addNewDriverText.headerForAdd}</h6>
        <span className="closeAddDriverBtn" onClick={() => dispatch(toggleAddNewDriverModal())}>
          <img src={CloseIcon} alt="Close icon" />
        </span>
      </div>
      <form className="addDriverForm">
        {isEditMode && (
          <>
            <FormInput
              id="firstname"
              label={addNewDriverText.nameSelect}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <FormInput
              id="lastname"
              label={addNewDriverText.lastnameSelect}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <FormDropdown
              id="vehicle"
              label={addNewDriverText.vehicleSelect}
              value={selectedVehicle}
              onChange={(e) => setSelectedVehicle(e.target.value)}
              options={vehicles}
              showDefaultOption={!selectedVehicle} // Show default if no vehicle is selected
            />
          </>
        )}
        <FormInput
          id="phone"
          label={addNewDriverText.phoneSelect}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          type="number"
        />
        {!isEditMode && (
          <FormInput
            id="email"
            label={addNewDriverText.emailSelect}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        )}

        <button
          className="saveDriverBtn"
          onClick={handleClick}
          type="button"
          disabled={isStatusLoading || (!isEditMode && email === "") || phone === ""}
        >
          {isStatusLoading ? <span className="loader"></span> : addNewDriverText.btnText}
        </button>
      </form>
    </div>
  );
}
