import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const createCompany = createAsyncThunk("company/createCompany", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const companyData = {
    c_name: data.c_name,
    c_lid: data.c_lid,
    d_cid: data.d_cid,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(companyData),
  };

  console.log(requestOptions);

  try {
    const response = await fetch(`${apiUrl}/create_company`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Company creation failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
