import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const fetchCustomers = createAsyncThunk(
  "addresses/getCustomers",
  async (data = {}, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;
    const { filters } = data;

    const queryParams = new URLSearchParams();

    // Append customer filter if present and not empty
    if (filters && filters.customer !== undefined && filters.customer !== "") {
      queryParams.append("customer", filters.customer);
    }

    // Append search query if present and not empty
    if (
      filters &&
      filters.searchQuery !== undefined &&
      filters.searchQuery !== ""
    ) {
      queryParams.append("customer", filters.searchQuery);
    }

    // Append other filters
    if (filters && filters.sort_by)
      queryParams.append("sort_by", filters.sort_by);
    if (filters && filters.sort_order)
      queryParams.append("sort_order", filters.sort_order);
    if (data.page) queryParams.append("page", data.page);
    if (data.per_page) queryParams.append("per_page", data.per_page);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/get_customer?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.error || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching customers failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
