import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const reqPassReset = createAsyncThunk(
  "passReset/reqPassReset",
  async (data, thunkAPI) => {
    const passResetData = {
      email: data.email,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(passResetData),
    };

    try {
      const response = await fetch(
        `${apiUrl}/request_password_reset`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Password reset request failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const reqPassResetSlice = createSlice({
  name: "passReset",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    passReset: null,
  },
  reducers: {
    resetPassReq: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reqPassReset.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(reqPassReset.fulfilled, (state, action) => {
        state.passReset = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(reqPassReset.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetPassReq } = reqPassResetSlice.actions;
export default reqPassResetSlice.reducer;
