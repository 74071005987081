import { createSlice } from "@reduxjs/toolkit";
import { updateDepartment } from "../thunks/updateDepartmentThunk";

const updateDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    department: null,
  },
  reducers: {
    resetUpdateDepartmentStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.department = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateDepartmentStatus } = updateDepartmentSlice.actions;
export default updateDepartmentSlice.reducer;
