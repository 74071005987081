import { createSlice } from "@reduxjs/toolkit";
import { updateCompany } from "../thunks/updateCompanyThunk";

const updateCompanySlice = createSlice({
  name: "companyUpdate",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    company: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCompany.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default updateCompanySlice.reducer;
