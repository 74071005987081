import { createSlice } from "@reduxjs/toolkit";
import { cancelPackage } from "../thunks/cancelPackageThunk";

const cancelPackageSlice = createSlice({
  name: "package",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    package: null,
  },
  reducers: {
    resetCancelPackageStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelPackage.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(cancelPackage.fulfilled, (state, action) => {
        state.package = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(cancelPackage.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetCancelPackageStatus } = cancelPackageSlice.actions;
export default cancelPackageSlice.reducer;
