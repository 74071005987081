import { createSlice } from "@reduxjs/toolkit";
import { fetchCustomers } from "../thunks/getCustomerThunk";
import { fetchCustomerById } from "../thunks/getCustomerByIdThunk";

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    customers: [],
    total_count: 0,
    filtered_count: 0,
    customer_names: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'error'
    error: null,
    customer: {},
  },
  reducers: {
    resetCustomersState(state) {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.customers;
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.customer_names = action.payload.customer_names;
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      })
      .addCase(fetchCustomerById.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCustomerById.fulfilled, (state, action) => {
        state.customer = action.payload; // Store the fetched customer
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(fetchCustomerById.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetCustomersState } = customersSlice.actions;
export default customersSlice.reducer;
