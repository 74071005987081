import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../thunks/registerDriverThunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const registerSlice = createSlice({
  name: "register",
  initialState: {
    status: "idle",
    error: null,
  },
  reducers: {
    resetRegisterState(state) {
      (state.status = "idle"), (state.error = null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

const persistConfig = {
  key: "register",
  storage,
};

export const { resetRegisterState } = registerSlice.actions;
export default persistReducer(persistConfig, registerSlice.reducer);
