// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deleteCustomerAddress {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  background-color: white;
  border-radius: 8px;
  color: #1b1f3ba6;
}

.deleteCustomerAddress img {
  cursor: pointer;
}

.deleteCustomerAddressHeading {
  display: flex;
  justify-content: space-between;
}

.deleteCustomerAddressHeading h6 {
  margin: 0;
  color: #1b1f3ba6;
  font-size: 20px;
  line-height: 26px;
}

.deleteCustomerBtns {
  display: flex;
  width: 100%;
  gap: 16px;
}

.cancelButton {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: 1px solid #a4a5b1;
  background-color: transparent;
  cursor: pointer;
  color: #676c7e;
}

.confirmButton {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
}

.confirmButton:disabled {
  background-color: #8cb6f1;
  cursor: not-allowed;
}

.addressText {
  color: #3082f6;
  font-weight: 800;
  line-height: 20.8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/DeleteCustomerAddress.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".deleteCustomerAddress {\r\n  width: 30%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n  padding: 32px;\r\n  background-color: white;\r\n  border-radius: 8px;\r\n  color: #1b1f3ba6;\r\n}\r\n\r\n.deleteCustomerAddress img {\r\n  cursor: pointer;\r\n}\r\n\r\n.deleteCustomerAddressHeading {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.deleteCustomerAddressHeading h6 {\r\n  margin: 0;\r\n  color: #1b1f3ba6;\r\n  font-size: 20px;\r\n  line-height: 26px;\r\n}\r\n\r\n.deleteCustomerBtns {\r\n  display: flex;\r\n  width: 100%;\r\n  gap: 16px;\r\n}\r\n\r\n.cancelButton {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: 1px solid #a4a5b1;\r\n  background-color: transparent;\r\n  cursor: pointer;\r\n  color: #676c7e;\r\n}\r\n\r\n.confirmButton {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.confirmButton:disabled {\r\n  background-color: #8cb6f1;\r\n  cursor: not-allowed;\r\n}\r\n\r\n.addressText {\r\n  color: #3082f6;\r\n  font-weight: 800;\r\n  line-height: 20.8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
