import { createSlice } from "@reduxjs/toolkit";
import { createCompany } from "../thunks/createCompanyThunk";

const companySlice = createSlice({
  name: "company",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    company: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCompany.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.company = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(createCompany.rejected, (state, action) => {
        console.log(action.payload);
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export default companySlice.reducer;
