import { useEffect, useRef, useState } from "react";

import "./DriverLocation.css";
import CloseIcon from "../../images/deliveries/deliveriesModals/xIcon.svg";
import Icon from "../../images/orderInformation/Icon.svg";
import { driverLocationText, orderInformationText } from "../../translations";
import { useEscapeKeyClose } from "../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../helperFunctions/customHooks/useClickOutsideClose";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch, useSelector } from "react-redux";
import { formatDateString } from "../../helperFunctions/formatDate";
import { formatTime } from "../../helperFunctions/formatTime";
import { getStatusStyles } from "../../helperFunctions/getStatusStyles";
import { toggleDriverLocationModal } from "../../redux/globalFunctions/globalFnSlice";

export function DriverLocation() {
  const { isDriverRoutes } = useSelector((state) => state.global);
  const deliveries = useSelector((state) => state.currentDeliveries.deliveries || state.currentDeliveries);
  const deliveriesState = useSelector((state) => state.currentDeliveries);
  const deliveryByIdState = useSelector((state) => state.deliveries);
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(() => dispatch(toggleDriverLocationModal()));
  useClickOutsideClose(modalRef, () => dispatch(toggleDriverLocationModal()));

  const [coordinates, setCoordinates] = useState(null);
  const [viewState, setViewState] = useState({
    zoom: 15,
  });
  const delivery = deliveries[0] || "";
  const deliveryById = useSelector((state) => state.deliveries.delivery);
  const mapboxToken = "pk.eyJ1IjoiZGF2b3JuIiwiYSI6ImNtMDZnNjg0czB0Y3Aya3NiMHFzY2xrbjYifQ.sMnCXULu1e1vuXy6xU4cRQ";

  const geocodeAddress = async () => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          !isDriverRoutes ? delivery.p_pickup_address : deliveryById.pickup_address
        )}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const [lng, lat] = data.features[0].center;
      setCoordinates({ lat, lng });
      setViewState((prevState) => ({
        ...prevState,
        latitude: lat,
        longitude: lng,
      }));
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
    }
  };

  const reverseGeocodeCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const address = data.features[0].place_name;
      updateAddress(address);
    } catch (error) {
      console.error("Error fetching the address:", error);
    }
  };

  useEffect(() => {
    geocodeAddress();
  }, [delivery, deliveryByIdState.status]);

  const handleMarkerDragEnd = (event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;
    setCoordinates({ lat, lng });
    reverseGeocodeCoordinates(lat, lng);
  };

  const statusStyles = getStatusStyles(
    !isDriverRoutes ? delivery.p_status : deliveryById.status,
    !isDriverRoutes ? formatDateString(delivery.p_order_date) : deliveryById.order_date,
    !isDriverRoutes ? formatTime(delivery.p_order_time) : deliveryById.order_time
  );

  const isStatusLoading = deliveriesState.status === "loading" || deliveryByIdState.status === "loading";

  return (
    <div className="driverLocationModal" ref={modalRef}>
      <div className="topChildDiv">
        <div style={{ display: "flex", gap: "16px" }}>
          <div className="driverLocationImgDiv">
            <img src={Icon} alt="Icon" />
          </div>
          <div className="driverInfoContainer">
            <div className="driverName"></div>
            <div className="orderNumber">
              {driverLocationText.headerText}
              {deliveriesState.status === "succeeded" && !isStatusLoading && (
                <span>{!isDriverRoutes ? delivery.p_id : deliveryById.package_id}</span>
              )}
            </div>
          </div>
        </div>
        <div>
          <img
            src={CloseIcon}
            alt="X icon"
            className="closeDriverLocationBtn"
            onClick={() => dispatch(toggleDriverLocationModal())}
          />
        </div>
      </div>
      {isStatusLoading && (
        <div className="notificationsDiv">
          <span className="blackLoader"></span>
        </div>
      )}
      {deliveriesState.status === "error" && (
        <div className="notificationsDiv">
          <div>No current deliveries for this driver.</div>
        </div>
      )}
      {deliveries.length > 0 && !isStatusLoading && deliveriesState.status === "succeeded" && (
        <div className="driverLocationContent">
          <div className="leftSideContent">
            {coordinates && (
              <Map
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                style={{ width: "100%", height: "100%" }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={mapboxToken}
              >
                <Marker
                  latitude={coordinates.lat}
                  longitude={coordinates.lng}
                  draggable={true}
                  onDragEnd={handleMarkerDragEnd}
                />
              </Map>
            )}
          </div>
          <div className="rightSideContent">
            <div className="orderInformationContent">
              <h6>{driverLocationText.informationHeader}</h6>
              <div style={{ display: "flex", alignItems: "center" }}>
                <small style={{ width: "70px" }}>{driverLocationText.text1}</small>
                <p>{!isDriverRoutes ? formatDateString(delivery.p_order_date) : deliveryById.order_date}</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <small style={{ width: "70px" }}>{driverLocationText.text2}</small>
                <p>{!isDriverRoutes ? formatTime(delivery.p_order_time) : deliveryById.order_time}</p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <small style={{ width: "70px" }}>{driverLocationText.text3}</small>
                <p>{!isDriverRoutes ? delivery.p_dropoff_address : deliveryById.dropoff_address}</p>
              </div>
            </div>
            <div className="shippingInfo">
              <div className="shipped">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img src={statusStyles.icon1} alt="Circle with dot" />
                  <div className={`line ${statusStyles.lineClass}`}></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <small>{statusStyles.smallText1}</small>
                  <p>{statusStyles.statusText1}</p>
                </div>
              </div>
              <div className="onTheWay">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img src={statusStyles.icon2} alt="Circle with dot" />
                  <div className={`line ${statusStyles.lineClass}`}></div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <small>{orderInformationText.text5}</small>
                  <p>{statusStyles.statusText2}</p>
                </div>
              </div>
              <div className="delivered">
                <img src={statusStyles.icon3} alt="Address icon" />
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <small>{statusStyles.smallText3}</small>
                  <p>{statusStyles.statusText3}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
