import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../constants";

export const deleteDriver = createAsyncThunk("driver/deleteDriver", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ u_id: data.u_id }),
  };

  try {
    const response = await fetch(`${apiUrl}/delete_driver`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Deleting driver failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});
